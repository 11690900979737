.local-page-not-for-clinical {
    font-weight: bold;
    margin-bottom: 30px;
    margin-top: 30px;
}

.local-page-container {
    width: 100vw;
    height: auto;
    margin-top: 20px;
    margin-left: 20px;
}


.version-number {
    position: relative;
    bottom: 0px;
    left: 0px;
    font-size: 0.9rem;
}

.summary>p {
    font-size: 0.9rem;
}

.security-disclaimer {
    width: 32vw;
    margin-bottom: 5px;
    font-size: 1rem;
}

.support-contact {
    font-size: 1rem;
    margin: 0;
}

.global-toolbar-for-local {
    position: fixed;
    right: 0;
    top: 0;
    z-index: 100;
}

.file-load-errors-alert {
    margin-top: 5px;
    max-width: 950px;
}

.file-load-errors {
    display: flex;
}

.file-load-errors .filename {
    width: 300px;
    word-break: break-all;
}

.hide-file-form {
    display: none;
}

/*.popup {*/
/*    position: fixed;*/
/*    width: 100%;*/
/*    height: 100%;*/
/*    top: 0;*/
/*    left: 0;*/
/*    right: 0;*/
/*    bottom: 0;*/
/*    margin: auto;*/
/*    background-color: rgba(0,0,0, 0.5);*/
/*}*/
.popup {
    position: absolute;
    left: 1%;
    right: 25%;
    top: 1%;
    /*bottom: 25%;*/
    margin: auto;
    background: white;
    opacity: 0.25;
}

.popup:hover {
    position: absolute;
    left: 1%;
    right: 25%;
    top: 1%;
    /*bottom: 25%;*/
    margin: auto;
    background: white;
    opacity: 0.9;
}

.home-panel {
    border-radius: 10px;
    color: var(--white);
    padding: 40px 30px;
}

.left {
    min-width: 40vw;
    margin-right: 10rem;
    font-size: 0.95rem;
}

.right {
    display: grid;
    min-width: 50vw;
    height: fit-content;
    place-items: center;
}

.mvision-logo {
    width: 100%;
    max-width: 250px;
    justify-self: center;
}

.home-logo {
    margin-bottom: 12px;
    display: flex;
    width: 81vw;
    place-content: space-between;
    animation: imageAnimation 3s linear;
}

.home-panel .subtitle {
    font-size: 10px;
}

.scan-progress-bar {
    margin-top: 10px;
}

.home-page>.panels {
    display: flex;
    justify-content: space-between;
}

.home-page .image-panel-container {
    align-self: center;
    flex-grow: 1;
}

.image-panel {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.home-carousel {
    width: 800px;
}

.home-carousel img {
    width: 800px;
}

.caption-box {
    height: 150px;
    text-align: center;
}

.rtviewer-btn {
    margin-top: 10px;
    margin-bottom: 15px;
}

.caption-box .caption {
    color: #fff;
    padding: 20px;
    background-color: #000;
    width: 800px;
    min-height: 130px;
}

#scan-selector {
    margin-right: 20px;
    margin-top: -10px;
    margin-bottom: 20px;
    min-width: 250px;
}

.landing-page-container {
    display: flex;
}

.landing-left {
    width: 50%;
}

.landing-right {
    width: 50%;
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: left;
}

.landing-image {
    height: 80%;
    max-width: 75%;
    animation: imageAnimation 3s linear;
    position: fixed;
    top: 40px;
    right: -100px;
}

@media (min-width: 1401px) {
    .landing-image {
        right: -75px;
    }
}

@media (max-width: 1200px) {
    .landing-image {
        max-width: 65%;
        right: -100px;
    }

    .home-logo-image {
        width: 80%;
        max-width: 500px;
    }
}

.local-page-container a {
    color: var(--light-turquoise);
}

.local-page-container a:hover {
    filter: brightness(1.2);
}

.logged-out-text p {
    margin-right: 5px;
}