.roi-select {
  font-size: 14px;
  margin-right: 8px;
}

/* put a bit of space between the label and the dropdown */
.roi-select label > div {
  margin-left: 4px;
}

/* override some annoying styles in react-select */
.roi-select div {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}