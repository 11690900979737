.notifications-container {
    position: fixed;
    z-index: 100;
    left: calc(50% - 350px);
    width: 700px;
}

.notifications-container .notification {
    position: relative;
    margin: 5px;
    width: 100%;
}

.notifications-container .notification .notification-text-container {
    display: flex;
    align-items: flex-start;
    flex-grow: 0;
}

.notifications-container .notification .notification-text {
    flex-shrink: 100;
    width: 100%;
}

.notifications-container .notification .show-details-link {
    margin-left: 20px;
    justify-self: flex-end;
    width: 130px;
    font-size: 15px;
    margin-top: 2px;
}