.coordinate-overlay {
    text-align: right;
    bottom: 5px;
    right: 9px;
}

.coordinate-list .coordinate {
    display: inline-block;
}

.coordinate-list .coordinate-section {
    margin-right: 10px;
}

.coordinate-list .coordinate-block:not(:first-of-type) {
    margin-left: 10px;
}

.coordinate-list .coordinate.decimals {
    width: 45px;
}

.coordinate-list .coordinate.no-decimals {
    width: 35px;
}

.coordinate-list .coordinate.unit {
    width: 28px;
}
