.user-settings .app-auth-row {
    margin-bottom: 10px;
}

.user-settings .notice {
    margin-bottom: 30px;
}

.user-settings .logout-button {
    margin-top: 30px;
}

.user-settings .form-group {
    margin-bottom: 40px;
}

.value-row-shift-checkbox {
    top: -12px;
}

.textarea-dicom-attr {
    width: 100%;
    border: 1px solid var(--grey);
    color: var(--white) !important;
    background-color: var(--secondary);
}

.textarea-dicom-attr:focus {
    border: 1px solid var(--primary);
    background-color: var(--secondary);
    box-shadow: 0 0 0 0.17rem var(--grey);
}

.dicom-attr-errors {
    margin-top: 10px;
}

.value-row-shift-dropdown {
    top: -8px;
}

.selected-dicom-attr {
    background-color: var(--light-turquoise);
}
.version-commit-id {
    font-size: small;
    margin-left: 5px;
}

.terms-document-link {
    color: var(--light-turquoise);
    text-decoration: none;
    cursor: pointer;
    margin: 0;
    padding: 4px 0; 
    background-color: var(--secondary);
    display: block; 
}

.terms-document-link:hover {
    text-decoration: underline;
}


.setting-item {
    margin-bottom: 20px;
}

.setting-title {
    font-weight: 500;
}

.setting-description {
    margin-top: 5px;
    font-size: smaller;
    width: 80%;
}
