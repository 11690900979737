.link-button {
    background: none !important;
    border: none !important; 
    padding: 0 !important;
    
    font-family: arial, sans-serif;
    color: rgb(27, 127, 177);
    cursor: pointer;
}

.link-button:hover {
    color: rgb(14, 100, 143);
    text-decoration: underline;
}
