.boolean-operations-toolbar {
  padding: 0 8px;
  font-size: 14px;
}

.boolean-operations-toolbar label > div {
  margin-left: 4px;
}

.boolean-operations-toolbar > * {
  margin-right: 15px;
}
