.global-main-toolbar {
    margin-top: 2px;
    margin-bottom: 2px;
    display: flex;
}

.global-main-toolbar>.toolbar-container {
    margin-left: auto;
}

.global-toolbar-buttons > div {
    display: inline-flex;
    gap: 5px;
    background-color: var(--primary);
    padding: 4px 5px;
    border-radius: 30px 0px 0px 30px;
    z-index: 5;
}

button#dropdown-button-apps {
    border-radius: 0.1rem 0.1rem 0.1rem 0.1rem;
}

.url-option a:link, .url-option a:visited, .url-option a:hover, .url-option a:active {
    color: var(--white);
    text-decoration: none;
}

.url-option:focus {
    background-color: var(--tertiary);
}

.global-toolbar-button > .dropdown-menu.show {
    top: 5px !important; 
}

.global-toolbar-buttons button>svg {
    /* ensure icons in toolbar are vertically centered */
    margin-bottom: 1px;
}

.global-toolbar-button {
    border-radius: 50px;
}

.toolbar-main-container button {
    background-color: #fff;
}