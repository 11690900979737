.segmentation-succeeded div {
    margin-bottom: 10px;
}

.structure-set-download-button {
    border: 1px solid var(--light-turquoise) !important;
}

.structure-set-download-button-icon {
    margin-right: 16px;
}

.structure-set-download-button:hover > .structure-set-download-button-icon {
    color: var(--white);
}

.save-rtdir {
    color: var(--white);
}

