.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
    background-color: var(--dark-turquoise) !important;
}

.css-1gtu0rj-indicatorContainer:hover {
    color: var(--grey) !important; 
}

.nav-link-image {
    margin-right: 5px;
}

.model-select-list {
    padding: 0;
}

.model-select-nav {
    position: sticky;
    width: 180px;
    top: 0;
}

.model-select-details {
    position: sticky;
    max-width: min-content;
    top: 0;
}

.worker-is-sleeping-message {
    margin-bottom: 10px;
}

.model-select-filters {
    position: fixed;
    margin-top: 20px;
    font-size: 13px;
}

.model-select-filters input[type=checkbox] {
    margin-top: 3px;
}

.model-select-details-box:nth-last-child(2) {
    margin-bottom: 40px;
}

.included-rois {
    font-size: 14px;
}

.included-rois textarea {
    font-size: 13px;
    background-color: var(--secondary) !important;
    color: var(--grey) !important;
}

.included-rois {
    font-size: 14px;
}

.included-rois textarea {
    font-size: 13px;
    background-color: var(--secondary) !important;
    color: var(--grey) !important;
}

.model-description p {
    font-size: 14px;
}

.model-text-entry {
    margin-top: 5px;
    background-color: var(--secondary);
    color: var(--white);
    margin-bottom: 20px;
}

.flash-on-reveal {
    animation: flash-border-colors 1.5s cubic-bezier(.17,.67,.83,.67);
}

@keyframes flash-border-colors {
    0% {
        background-color: #fdfcdd;
    }
    40% {
        background-color: #fffa93;
    }
    100% {
        background-color: inherit;
    }
}