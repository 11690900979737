.new-structure-set-radio-options .form-row:not(:first-child) {
    margin-top: 5px;
}

.new-structure-set-radio-options .option-right-side {
    padding-left: 24px;
}

.anonymization-settings-button {
    margin-bottom: 10px;
    border: 1px solid var(--white);
    color: var(--light-turquoise);
    padding: 10px;
    border-radius: 4px;
    align-items: center;
    gap: 10px;
}

.anonymization-settings-panel {
    margin-bottom: 0px;
    margin-left: 16px;
}

.new-structure-set-modal-body {
    min-height: 470px;
}
