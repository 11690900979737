

span.autocontour-in-progress {
    font-size: smaller;
}

/* styles for validation */
span.invalid-prediction-model {
    font-size: smaller;
    color: #ee892c;
}

span.autocontour-in-progress.autocontour-not-available {
    color: #ee892c;
}

.model-customization-list {
    cursor: pointer;
    padding-top: 55px;
}


.model-customization-list > .list-group-item {
    background-color: var(--tertiary);
    font-size: small;
}

.model-customization-list > .list-group-item:hover {
    border-color: var(--grey);
}

.model-customization-list > .list-group-item.active {
    border-color: var(--light-turquoise);
}

.output-icon {
    width: 25px;
    margin: 0;
    margin-right: 10px;
}

.model-select-item {
    display: flex;
}

.filter-container {
    position: fixed;
    z-index: 10;
    background: var(--secondary);
    margin-top: -16px;
}

.filter-search-box {
    top: 16px;
}

.filter-models-field {
    width: 237px;
    border: 0.9px solid var(--grey);
    border-radius: 5px;
    background-color: var(--tertiary);
    color: var(--white);
}

.filter-models-field:focus {
    background-color: var(--tertiary);
    border: 0.9px solid var(--light-turquoise);
    box-shadow: none;
    color: var(--white);
}

.filter-models-field-icon {
    border: 0.9px solid var(--grey);
    background-color: var(--tertiary);
    color: var(--white);
}
