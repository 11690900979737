
.rtviewer-container {
    width: 100%;
    background-color: var(--primary);
    color: var(--white);
}

.rtviewer-content {
    color: var(--white);
    background-color: var(--primary);
    height: calc(100% - 30px) !important;
}

.left-panel {
    padding-left: 12px;
    padding-right: 7px;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.side-panel {
    user-select: none;
}

.cannot-edit-notification {
    background-color: rgb(254, 254, 190);
    margin-left: 5px;
    padding-top: 3px;
    padding-left: 10px;
    padding-right: 10px;
}

div.left-top-panel { 
    margin-top: 2px;
    margin-bottom: 15px;
}

div.left-bottom-panel {
    margin-top: 2px;
    margin-bottom: 2px;
    overflow-y: auto;
    height: 100%;
    width: 100%;
}

.right-panel {
    width: 100%;
    height: 100%;
    /* border-style:solid; 
    border-color:blue;  */
}

.Resizer {
    background: #000;
    opacity: .2;
    z-index: 0;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -moz-background-clip: padding;
    -webkit-background-clip: padding;
    background-clip: padding-box;
}

 .Resizer:hover {
    -webkit-transition: all 2s ease;
    transition: all 2s ease;
}

 .Resizer.horizontal {
    height: 11px;
    margin: -5px 0;
    border-top: 5px solid rgba(255, 255, 255, 0);
    border-bottom: 5px solid rgba(255, 255, 255, 0);
    cursor: row-resize;
    width: 100%;
}

.Resizer.horizontal:hover {
    border-top: 5px solid rgba(0, 0, 0, 0.5);
    border-bottom: 5px solid rgba(0, 0, 0, 0.5);
}

/* .Resizer.vertical {
    width: 11px;
    margin: 0 -5px;
    border-left: 5px solid rgba(255, 255, 255, 0);
    border-right: 5px solid rgba(255, 255, 255, 0);
    cursor: col-resize;
}

.Resizer.vertical:hover {
    border-left: 5px solid rgba(0, 0, 0, 0.5);
    border-right: 5px solid rgba(0, 0, 0, 0.5);
} */
.Resizer.disabled {
  cursor: not-allowed;
}
.Resizer.disabled:hover {
  border-color: transparent;
}

.image-viewer-grid {
    position: absolute;
     width: 100%;
     height: 100%;
     display: grid;
     grid-template-columns: 1fr;
     grid-template-rows: min-content 1fr min-content;
     grid-template-areas:
       "one"
       "two"
       "three"
}
   
.image-viewer-toolbar {
    /* background: red;  */
    grid-area: one;
    
    /* prioritise toolbar UI interaction over the canvas */
    z-index: 2;
}

.image-viewer-canvases {
    /* background: yellow;   */
    grid-area: two;
}

.section-title {
    margin-top: 10px;
    font-size: 14px;
    font-weight: 600;
    width: 100%;
}

.structures-section {
    width: auto;
    margin-bottom: 5px;
    display: flex;
    justify-content: space-between;
}

/* move the Structures title a bit down if we have a grading sheet */
.structures-section.has-grading-sheet {
    margin-bottom: -3px;
}

.rtviewer-logo {
    cursor: pointer;
    margin-right: 25px;
    margin-bottom: 10px;
}

.roi-list-download-button button {
    font-size: 12px;
    padding: 2px 4px;
    /* width: 100%; */
    text-align: left;
}

.roi-list-download-button .dropdown-toggle {
    max-width: 24px;
    text-align: right;
}

.roi-list-download-button .dropdown-menu {
    font-size: 12px;
    padding: 4px 0;
}

.roi-list-download-button .dropdown-item {
    padding: 4px 16px;
}

.roi-list-download-button .button-label {
    margin: 0 4px;
}

.roi-list-download-button svg {
    margin-right: 3px;
}