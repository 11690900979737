
.brush-toolbar {
    font-size: 14px;
    margin-left: 10px;
}
.line-toolbar {
    font-size: 14px;
    margin-left: 10px;
}
.select-a-roi-notification {
    margin-top: 4px;
    margin-bottom: 4px;
}

.brush-width-label {
    margin-top: 7px;
}
.brush-width-value {
    margin: 5px;
    margin-left: 10px; 
    margin-right: 10px;
    width: 50px;
}
.contouring-undo-buttons {
    margin-left: 7px;
}
.form-check {
    margin-top: 5px;
}