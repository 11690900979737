.roi-table {
    margin-bottom: 20px;
    margin-top: 5px;
    display: block;
    user-select: none;
}

.roi-table thead {
    border-bottom: 1px solid black;
}

.roi-table tbody {
   font-size: 12px;
}

.roi-table .roi-item:not(.selected-roi):hover {
    background-color: var(--tertiary);
}

.roi-table tbody tr:first-child td {
    padding-top: 5px;
}

.roi-table tbody tr.last-roi-row td {
    padding-bottom: 5px;
}

th .form-check {
    margin-left: -1px;
    margin-top: -13px;
    top: -5px;
}
tr .visibility-column .form-check {
    margin-left: -1px;
    margin-top: -18px;
    top: 2px;
}
tr .grading-column .form-check {
    margin-left: 5px;
    margin-top: 5px;
}

div.grading-section-column {
    /* push this column to the right because otherwise it overlaps the column to the left of it */
    padding-left: 0;
    left: 15px;
}

.roi-table .visibility-column   { min-width: 18px;  max-width: 18px; }
.roi-table .color-column        { width: 30px; }
.roi-table .roi-name-column     { width: 145px; }
.roi-table .grading-column      { width: 145px; }

.roi-table th.visibility-column {
    padding-bottom: 10px;
    vertical-align: bottom;
}

.roi-table th .form-check {
    top: -2px;
}

.roi-name-column {
    cursor: default;
    word-break: break-word;
}

tr.selected-roi {
    background-color: var(--secondary);
}

.color-square {
    float: left;
    width: 20px;
    height: 20px;
    margin-right: 5px;
    border: 1px solid rgba(0, 0, 0, .2);
}

.color-square.empty {
    border: 0;
    width: 22px;
    height: 22px;
}

.new-roi-button td {
    padding-top: 10px;
}

.new-roi-button .btn-group {
    width: 100%;
}

.new-roi-button button {
    font-size: 12px;
    padding: 2px 4px;
    width: 100%;
    text-align: left;
}

.new-roi-button .dropdown-toggle {
    max-width: 24px;
    text-align: right;
}

.new-roi-button .dropdown-menu {
    font-size: 12px;
    padding: 4px 0;
}

.new-roi-button .dropdown-item {
    padding: 4px 16px;
}

.grading-column {
    background-color: rgb(221, 221, 221);
    padding-right: 5px;
}

/* rounder corners for grading sheet */
th.grading-column { border-radius: 5px 5px 0 0;  }
tr.last-roi-row td.grading-column { border-radius: 0 0 5px 5px;  }

.grading-sheet-header {
    display: flex;
}

.grading-sheet-header > .title {
    font-size: 12px;
    font-weight: 500;
    margin-left: 5px;
}

.grading-sheet-header .grading-sheet-saving-icon {
    margin-top: -4px;
    margin-right: 2px;
    flex-grow: 1;
    text-align: right;
}

.grading-button, .grading-button button {
    padding: 0px 6px;
    font-size: 11px;
}

.grading-state-button button {
    padding: 0 2px;
}

.grading-button.unsure input {
    top: -2px;
}

.grading-button .dropdown-toggle {
    font-size: 11px;
}

.grading-button .dropdown-item {
    font-size: 13px;
}

.grading-button .form-check, .grading-button label {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

button.icon-button {
    border: 0;
}

.icon-button > svg {
    margin-bottom: 2px;
}

button.grading-button.icon-button {
    font-size: 13px;
}

.roi-context-menu {
    font-size: 13px;
}
