:root {
    --primary: #2c2c2c;
    --white: #f3f3f3;
    --grey: #c2c1c1;
    --light-grey: #dfdfdf;
    --secondary: #383838;
    --tertiary: #444444;
    --table-odd-row: #272727;
    --lock-red: #dc3545;
    --dark-blue: #006a6f;
    --dark-turquoise: #016f83;
    --light-turquoise: #02b1c0;
    --orange: #f49800;
    --light-green: #35dc71;
    --card-color: rgb(20, 20, 20);

    overflow-x: hidden;
    transition: 0.25s ease-in;
    background-color: var(--primary);
}


/* Poppins font with font weight 100 (Thin) */
@font-face {
    font-family: 'Poppins';
    src: url("/fonts/Poppins/Poppins-Thin.ttf") format('truetype');
    font-weight: 100;
    font-style: normal;
}

/* Poppins font with font weight 200 (Extra Light) */
@font-face {
    font-family: 'Poppins';
    src: url('/fonts/Poppins/Poppins-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

/* Poppins font with font weight 300 (Light) */
@font-face {
    font-family: 'Poppins';
    src: url('/fonts/Poppins/Poppins-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

/* Poppins font with font weight 400 (Normal) */
@font-face {
    font-family: 'Poppins';
    src: url("/fonts/Poppins/Poppins-Regular.ttf") format('truetype');
    font-weight: 400;
    font-style: normal;
}

/* Poppins font with font weight 500 (Medium) */
@font-face {
    font-family: 'Poppins';
    src: url("/fonts/Poppins/Poppins-Medium.ttf") format('truetype');
    font-weight: 500;
    font-style: normal;
}

/* Poppins font with font weight 600 (Semi Bold) */
@font-face {
    font-family: 'Poppins';
    src: url("/fonts/Poppins/Poppins-SemiBold.ttf") format('truetype');
    font-weight: 600;
    font-style: normal;
}

/* Poppins font with font weight 700 (Bold) */
@font-face {
    font-family: 'Poppins';
    src: url("/fonts/Poppins/Poppins-Bold.ttf") format('truetype');
    font-weight: 700;
    font-style: normal;
}

/* Poppins font with font weight 800 (Extra Bold) */
@font-face {
    font-family: 'Poppins';
    src: url("/fonts/Poppins/Poppins-ExtraBold.ttf") format('truetype');
    font-weight: 800;
    font-style: normal;
}

/* Poppins font with font weight 900 (Black) */
@font-face {
    font-family: 'Poppins';
    src: url("/fonts/Poppins/Poppins-Black.ttf") format('truetype');
    font-weight: 900;
    font-style: normal;
}

@keyframes imageAnimation {
    0% {
        opacity: 0;
        z-index: 1;
    }
    100% {
        opacity: 1;
        z-index: 2;
    }
}


body {
    font-family: 'Poppins', sans-serif;
}

a {
    color: var(--light-turquoise);
}


a:hover {
    color: var(--dark-turquoise);
}

input {
    accent-color: var(--dark-turquoise);
}

input:hover {
    accent-color: var(--light-turquoise);
}

.visible {
    display: inline;
}

.invisible {
    display: none;
}

.clickable-glyph, .clickable-glyph-btn {
    cursor: pointer;
    color: var(--light-turquoise);
    font-size: 16px;
}

.clickable-glyph:hover {
    color: var(--dark-turquoise);
}

.clickable-glyph.emphasis {
    cursor: pointer;
    color: var(--light-turquoise);
}

.clickable-glyph.emphasis:hover {
    color: var(--dark-turquoise);
}

:root {
    --theme-color-dark: #212323;
    --theme-color-mid: #006f83;
    --theme-color-bright: #00b1bf;
}

html {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

body {
    background: linear-gradient(131deg, var(--primary) 44.98%, #254A4F 65.36%, #008393 90.97%, var(--light-turquoise) 99.79%);
    min-height: 100vh;
    height: auto;
}

.show > .btn-primary.dropdown-toggle {
    color: var(--white);
    background-color: var(--dark-turquoise);
    border-color: var(--dark-turquoise);
}

.btn-primary.dropdown-toggle:actve {
    background-color: var(--light-turquoise) !important;
}

.btn-primary:hover {
    border-color: var(--light-turquoise);
}

.dropdown-menu.show {
    background-color: var(--primary);
}

.dropdown-menu.show > a {
    color: var(--white);
}

.dropdown-menu.show > a:hover {
    background-color: var(--tertiary);
}

.dropdown-menu.show > button {
    color: var(--white);
}

.dropdown-menu.show > button:hover {
    background-color: var(--tertiary);
}

.btn-danger {
    border-color: var(--lock-red) !important;
    color: var(--lock-red);
}

.sticky-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    background: var(--primary);
    color: var(--white);
    width: 100%;
    height: 30px;
    z-index: 10;
    text-align: center;
}


.info1 {
    width: 65%;
    min-width: 450px;
    font-weight: 400;
    margin: 0;
    align-items: center;
}

.rtstruct-form {
    display: flex;
    align-items: baseline;
    gap: 20px;
    margin-top: 10px;
}

.dark-file-input-container {
    display: inline-block;
    position: relative;
}

.dark-file-input-label {
    background-color: #333; /* Dark background color */
    color: white; /* White text color */
    padding: 8px 16px; /* Adjust padding as needed */
    border-radius: 4px; /* White corners */
    cursor: pointer;
}

/* Hide the input */
.dark-file-input {
    display: none;
}

.btn  {
  background-color: var(--secondary);
  border-color: var(--tertiary);
}

.btn:hover {
    background-color: var(--tertiary);
}

.btn.active,.btn:active {
    background-color: var(--dark-turquoise) !important;
    border-color: var(--tertiary) !important;
}

.btn:focus {
    box-shadow: 0 0 0 0.175rem color-mix(in srgb, var(--light-turquoise) 40%, transparent) !important;
}

input, textarea {
    background-color: var(--secondary);
    color: var(--white);
    border: 1px solid var(--grey);
    border-radius: 5px;
}

input:focus-visible, textarea:focus-visible {
    outline: none;
    border: 1px dashed var(--white);
}

.btn-outline-primary:not(:disabled) {
    background-color: var(--secondary) !important;
    color: var(--white) !important;
    border-color: var(--dark-turquoise) !important;
}

.btn-outline-primary:not(:disabled):hover {
    background-color: var(--tertiary) !important;
    color: var(--white) !important;
    border-color: var(--light-turquoise) !important;
}

.btn-toolbar {
    align-items: center;
}

.btn-outline-secondary {
    color: var(--grey) !important;
    border-color: var(--grey) !important;
}

.btn-outline-secondary:hover {
    background-color: var(--tertiary) !important;
    color: var(--white) !important;
}

.btn-outline-info {
    background-color: var(--secondary) !important;
    color: var(--white) !important;
    border-color: var(--dark-turquoise) !important;
}

.btn-outline-info:hover {
    background-color: var(--tertiary) !important;
    color: var(--white) !important;
    border-color: var(--light-turquoise) !important;
}

.btn-default:hover {
    border-color: var(--dark-turquoise) !important;
}

.btn-default:disabled,.btn-outline-primary:disabled,.btn-outline-secondary:disabled,.btn-outline-info:disabled {
    color: var(--grey) !important;
    border-color: var(--grey) !important;
}

.btn-default:hover:disabled,.btn-outline-primary:hover:disabled,.btn-outline-secondary:hover:disabled,.btn-outline-info:hover:disabled {
    background-color: var(--secondary) !important;
}

.modal-content {
    background-color: var(--secondary);
    color: var(--white);
}

:not(.notification-alert) > .close {
    color: var(--white) !important;
}

.col-card-btn {
    border-radius: 50px;
    padding: 0.35rem;
    padding-left: 0.4rem;
    padding-right: 1.5rem;
    text-align: right;
}

.col-card-btn > img {
    margin-right: 1.6rem;
}

.col-card-btn:hover > img {
    filter: brightness(1.2);
}

.turquoise {
    background-color: var(--dark-turquoise) !important;
    color: var(--white) !important;
    font-weight: 500;
}

.turquoise:hover {
    background-color: var(--light-turquoise) !important;
    color: var(--white) !important;

}

.turquoise-l {
    background-color: var(--light-turquoise) !important;
    color: var(--white) !important;
    font-weight: 500; 
}

.turquoise-l:hover {
    background-color: var(--dark-turquoise) !important;
}

.no-selection {
    cursor: default;
}
